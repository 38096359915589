import React from "react"
import Gallery from "react-grid-gallery"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BackToTop from "../components/back-to-top"
import { Container, Row, Col } from "reactstrap"
import Gallery1 from "../images/gallery/Dc-dRoA.jpeg"
import Gallery2 from "../images/gallery/7dlelEF-.jpeg"
import Gallery3 from "../images/gallery/9Li3pb14.jpeg"
import Gallery4 from "../images/gallery/AHJ8MQ9G.jpeg"
import Gallery5 from "../images/gallery/CD-Gkn-c.jpeg"
import Gallery6 from "../images/gallery/cYN4dPcY.jpg"
import Gallery7 from "../images/gallery/tb3Lyguo.jpeg"
import Gallery8 from "../images/gallery/HQ8hvgcY.jpeg"
import Gallery9 from "../images/gallery/jqQDdTiG.jpeg"
import Gallery10 from "../images/gallery/PdomnWjK.jpeg"
import Gallery11 from "../images/gallery/qkAjQbAD.jpeg"
import Gallery12 from "../images/gallery/sl1AejFf.jpeg"
import Gallery13 from "../images/gallery/ss4JsbF.jpeg"
import Gallery14 from "../images/gallery/subZjD3d.jpeg"
import Gallery15 from "../images/gallery/TEnImfmv.jpeg"
import Gallery16 from "../images/gallery/Shc1amR.jpg"
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component"

const HospitalGallery = ({ location }) => {
  const IMAGES = [
    {
      src: Gallery10,
      thumbnail: Gallery10,
    },
    {
      src: Gallery11,
      thumbnail: Gallery11,
    },
    {
      src: Gallery1,
      thumbnail: Gallery1,
    },
    {
      src: Gallery16,
      thumbnail: Gallery16
    },
    {
      src: Gallery8,
      thumbnail: Gallery8,
    },

    {
      src: Gallery2,
      thumbnail: Gallery2,
      caption: "Boats (Jeshu John - designerspics.com)",
    },
    {
      src: Gallery3,
      thumbnail: Gallery3,
    },
    {
      src: Gallery4,
      thumbnail: Gallery4,
    },
    {
      src: Gallery12,
      thumbnail: Gallery12,
    },
    {
      src: Gallery5,
      thumbnail: Gallery5,
    },
    {
      src: Gallery6,
      thumbnail: Gallery6,
    },
    {
      src: Gallery9,
      thumbnail: Gallery9,
    },
    {
      src: Gallery7,
      thumbnail: Gallery7,
    },
    {
      src: Gallery13,
      thumbnail: Gallery13,
    },
    {
      src: Gallery14,
      thumbnail: Gallery14,
    },
    {
      src: Gallery15,
      thumbnail: Gallery15,
    },
  ]
  return (
    <Layout>
      <Seo title="Hospital Gallery" />
      <div className="hero-adjustment pb-0">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-only-heading inner-page-title text-right arthroscopy-hero noVideoInner">
              <h1>Hospital Gallery</h1>
            </div>
          </div>
        </div>
      </div>
      <Container className="acl-container my-5">
        <Row className="border-none">
          <Col md={12}>
            <LazyLoadComponent>
              <Gallery images={IMAGES} />
            </LazyLoadComponent>
          </Col>
        </Row>
      </Container>
      <BackToTop />
    </Layout>
  )
}

export default HospitalGallery
